<template>
    <v-container>
        <div style="margin-bottom: 20px;">
            <span>{{ message }}</span>
        </div>
        <div style="margin-bottom: 20px;">
            <v-btn @click="cleanCache()">ניקוי זיכרון זמני</v-btn>
        </div>
        <v-form @submit.prevent="submitHandler" ref="form">
            <v-radio-group v-model="selectedOption" row>
                <v-radio label="הוספת עיר" value="city"></v-radio>
                <v-radio label="הוספת מדינה" value="country"></v-radio>
            </v-radio-group>
            <div style="margin-bottom: 20px;">
                <span>הוסף שם בעברית</span>
                <div style="margin-bottom: 20px; display: flex;">
                    <v-text-field style="max-width: 300px;" v-model="newCityOrCountryHe" label="שם העיר בעברית" :rules="[v => !!v || 'שדה חובה']" required ></v-text-field>
                </div>
            </div>
            <div style="margin-bottom: 20px;">
                <span>הוסף שם באנגלית</span>
                <div style="margin-bottom: 20px; display: flex;">
                    <v-text-field style="max-width: 300px;" v-model="newCityOrCountryEn" label="שם המדינה בעברית" :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                </div>
            </div>
            <v-btn @click="addCityOrCountry()">הוסף</v-btn>
        </v-form>
    </v-container>
</template>
<script>
import AddRestaurant from '@/components/admin/AddRestaurant.vue'
import EditRestaurant from '@/components/admin/EditRestaurant'
import ApiServices from '@/services/api.service'

export default {
    components: {
        AddRestaurant,
        EditRestaurant,
    },
    data() {
        return {
            addRestaurant: false,
            editRestaurant: false,
            message: "",
            activeTab: 0,
            adminSettings: true,
            newCityOrCountryHe: "",
            newCityOrCountryEn: "",
            selectedOption: "city",
            tabs: ["הוספת מסעדה חדשה", "עריכת מסעדה", "הגדרות מנהל"]
        }
    },
    methods: {
        async cleanCache() {
            try {
                let api = process.env.VUE_APP_BASE_URL + "/admin/settings_clean_cache";
                const token = localStorage.getItem("token");
                const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.message = "שגיאה!";
                    this.hideMessage();
                } else if (res.status === 200) {
                    this.message = "בוצע בהצלחה";
                    this.hideMessage();
                }
            } catch (error) {
                this.message = error.message;
                this.hideMessage();
            }
        },
        async addCityOrCountry() {
            try {
                if (this.$refs.form.validate()) {
                let api = process.env.VUE_APP_BASE_URL + "/admin/settings_add_city_or_country_name";
                const token = localStorage.getItem("token");
                const jsonBody = JSON.stringify({ nameHe: this.newCityOrCountryHe, nameEn: this.newCityOrCountryEn, type: this.selectedOption});
                const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.message = "שגיאה!";
                    this.hideMessage();
                } else if (res.status === 200) {
                    this.message = "בוצע בהצלחה";
                    this.newCityOrCountryHe = "";
                    this.newCityOrCountryEn = "";
                    this.hideMessage();
                }
            }
            } catch (error) {
                this.message = error.message;
            }
        },
        hideMessage() {
            setTimeout(() => {
                this.message = "";
            }, 4000);
        }
    },
    mounted() {
    },
}
</script>
<style scoped>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-right: 5px;
    margin-left: 5px;
}
</style>