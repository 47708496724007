<template>
  <v-container>

    <!-- מודעת גוגל -->
    <AdSense />


    <v-row class="mt-10 d-flex justify-center" style="margin-bottom: 70px;">
      <v-col cols="12" sm="11" md="8" style="background-color: rgb(255, 255, 255);">

        <v-row no-gutters style="padding-bottom: 0px !important">

          <v-col cols="12" style="margin-top: 50px; ">
            <v-btn v-if="isAdmin" style="margin-top: 50px;" @click="moveToEdit()">ערוך</v-btn>

            <a href="#" @click.prevent="openFancybox(getPicture)" data-caption="Optional caption here">
              <v-img :src="getPicture" height="300" style="border-top-left-radius: 15px; border-top-right-radius: 15px;"
                :contain="!restDetails.logo">
              </v-img>
            </a>

          </v-col>
        </v-row>

        <v-row no-gutters style="padding-bottom: 0px !important;">
          <v-col cols="12">
            <div style="height: 40px;  width: 100%; display: flex; justify-content: center; background-color: #fbfcf7;">
              <span style="font-size: x-large; color: #062a4e; font-weight: bold;">{{
                restDetails.name }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <div class="d-flex justify-space-between" style="background-color: #f0f0f0; padding: 16px;">
              <!-- Navigation -->
              <v-btn icon large @click="navigate()" class="mx-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon large color="#4CAF50" v-on="on">mdi-navigation</v-icon>
                  </template>
                  <span>{{ $t('restDetails.action_navigate') }}</span>
                </v-tooltip>
              </v-btn>

              <!-- Call -->
              <v-btn icon large @click="call()" class="mx-2" :disabled="!restDetails.phone">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon large color="#2196F3" v-on="on">mdi-phone</v-icon>
                  </template>
                  <span>{{ $t('restDetails.action_call') }}</span>
                </v-tooltip>
              </v-btn>

              <!-- Website -->
              <v-btn icon large :href="restDetails.website" target="_blank" class="mx-2" :disabled="!restDetails.website">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon large color="#FFC107" v-on="on">mdi-earth</v-icon>
                  </template>
                  <span>{{ $t('restDetails.action_website') }}</span>
                </v-tooltip>
              </v-btn>

              <!-- Share -->
              <v-btn icon large @click="shareDialog = true" class="mx-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon large color="#E91E63" v-on="on">mdi-share-variant</v-icon>
                  </template>
                  <span>{{ $t('restDetails.action_share') }}</span>
                </v-tooltip>
              </v-btn>
            </div>
          </v-col>
        </v-row>


        <v-row no-gutters>
          <v-col cols="12" sm="4" style="background-color: #f6f6f6;">
            <div style="border-left: 0.5px solid #d3d1d1;">
              <span style="font-size: small; color: grey; padding-top: 3px; padding-right: 3px;">{{ $t('restDetails.title_details') }}</span>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{ $t('restDetails.title_category') }}</span>
              <div>
                <span style="font-size: large; color: #062a4e; padding-right: 5px;">{{
                   constants.categories[restDetails.category_web][$i18n.locale] }}</span>
              </div>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{ $t('restDetails.title_sub_category') }}</span>
              <div>
                <span v-for="item in restDetails.sub_category_web"
                  style="font-size: large; color: #062a4e; padding-right: 5px;">{{ constants.sub_categories[item][$i18n.locale] }}, </span>
                <span v-if="!restDetails?.sub_category_web[0]"
                  style="font-size: large; color: #062a4e; padding-right: 5px;"></span>
              </div>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{ $t('restDetails.title_type') }}</span>
              <div>
                <span v-for="item in restDetails.type_web" style="font-size: large; color: #062a4e; padding-right: 5px;">{{
                   constants.types[item][$i18n.locale] }}, </span>
                <span v-if="!restDetails?.type_web[0]" style="font-size: large; color: #062a4e; padding-right: 5px;"></span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4" style=" background-color: #f6f6f6;">
            <div style="border-left: 0.5px solid #d3d1d1;">
              <span style="font-size: small; color: grey; padding-top: 3px; padding-right: 3px;">{{ $t('restDetails.title_address') }}</span>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{ $t('restDetails.street') }}</span>
              <div>
                <span style="font-size: large; color: #062a4e;">{{ restDetails.address }}</span>
              </div>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey">{{ $t('restDetails.city') }}</span>
              <div>
                <span style="font-size: large; color: #062a4e; padding-right: 5px;">{{ restDetails.city }}</span>
              </div>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{ $t('restDetails.country') }}</span>
              <div>
                <span style="font-size: large; color: #062a4e; padding-right: 5px;">{{ restDetails.country }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4" style="background-color: #f6f6f6;">
            <span style="font-size: small; color: grey; padding-top: 3px; padding-right: 3px;">{{ $t('restDetails.title_open_hours') }}</span>
            <span v-if="isOpen === 'true'"
              style="font-size: small; font-weight: bold; color: green; padding-top: 3px; padding-right: 10px;">{{ $t('restDetails.open') }}</span>
            <span v-else-if="isOpen === 'false'"
              style="font-size: small; color: rgb(219, 5, 5); font-weight: bold; padding-top: 3px; padding-right: 10px;">
              {{ $t('restDetails.close') }}</span>
            <span v-else-if="isOpen === 'isEmpty'"></span>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px; min-height: 189px;"
              v-if="restDetails.open_hours.thursday.open">
              <span style="font-size: small; color: grey;">{{$t('restDetails.day')}}</span>
              <div>
                <span style="font-size: medium; color: #062a4e;">{{$t('restDetails.sunday')}}: {{ restDetails.open_hours.sunday.open }} - {{
                  restDetails.open_hours.sunday.close }} , </span>
                <span style="font-size: medium; color: #062a4e;">{{$t('restDetails.monday')}}: {{ restDetails.open_hours.monday.open }} - {{
                  restDetails.open_hours.monday.close }}</span>
                <div>
                  <span style="font-size: medium; color: #062a4e;">{{$t('restDetails.tuesday')}}: {{ restDetails.open_hours.tuesday.open }} - {{
                    restDetails.open_hours.tuesday.close }} , </span>
                  <span style="font-size: medium; color: #062a4e;">{{$t('restDetails.wednesday')}}: {{ restDetails.open_hours.wednesday.open }} - {{
                    restDetails.open_hours.wednesday.close }}</span>
                </div>
                <div>
                  <span style="font-size: medium; color: #062a4e;">{{$t('restDetails.thursday')}}: {{ restDetails.open_hours.thursday.open }} - {{
                    restDetails.open_hours.thursday.close }} , </span>
                  <span style="font-size: medium; color: #062a4e;">{{$t('restDetails.friday')}}: {{ restDetails.open_hours.friday.open }} - {{
                    restDetails.open_hours.friday.close }}</span>
                </div>
                <span style="font-size: medium; color: #062a4e;">{{$t('restDetails.saturday')}}: {{ restDetails.open_hours.saturday.open }} - {{
                  restDetails.open_hours.saturday.close }}</span>
              </div>
            </div>
            <div v-else-if="!isLoggedIn">
              <span
                style="padding: 10px; margin-inline-start: 20px; font-size: medium; font-weight: bold; color: #062a4e;">
                {{$t('restDetails.add_opening_hours')}}
                <router-link to="/login" style="color: rgb(10, 10, 197);">{{$t('restDetails.login')}}</router-link>
              </span>
            </div>

          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="4" style="background-color: #f6f6f6;">
            <div style="border-left: 0.5px solid #d3d1d1;">
              <span style="font-size: small; color: grey; padding-top: 3px; padding-right: 3px;">{{$t('restDetails.title_kashrut')}}</span>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{$t('restDetails.kashrut_name')}}</span>
              <div>
                <span style="font-size: large; color: #062a4e; padding-right: 5px;">{{ constants.kashruyot[restDetails.kashrut.kashrut_name_web][$i18n.locale] }}</span>
              </div>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{$t('restDetails.badatz_local')}}</span>
              <div>
                <span style="font-size: large; color: #062a4e; padding-right: 5px;">{{
                  restDetails.kashrut.local_kashrut_name }}</span>
              </div>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px;">
              <span style="font-size: small; color: grey;">{{$t('restDetails.kashrut_more_details')}}</span>
              <div>
                <span style="font-size: large; color: #062a4e; padding-right: 5px;">{{ restDetails.kashrut.kashrut_details
                }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4" style=" background-color: #f6f6f6;">
            <div style="border-left: 0.5px solid #d3d1d1;">
              <span style="font-size: small; color: grey; padding-top: 3px; padding-right: 3px;">{{$t('restDetails.other_details')}}</span>
            </div>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px; min-height: 189px;">
              <span style="color: #062a4e;">{{ restDetails.details }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4" style="background-color: #f6f6f6;">
            <span style="font-size: small; color: grey; padding-top: 3px; padding-right: 3px;">{{$t('restDetails.kosher_certificate')}}</span>
            <div style="border: 0.5px solid #d3d1d1; padding: 5px; min-height: 189px;" class="d-flex justify-center">
              <a v-if="restDetails.kashrut.certificate" href="#"
                @click.prevent="openFancybox(host + '/certificates' + restDetails.kashrut.certificate)"
                data-fancybox="gallery" data-caption="Optional caption here">
                <v-img height="180" :src="host + '/certificates' + restDetails.kashrut.certificate" contain></v-img>
              </a>
              <div v-else>
                <div>
                  <span style="font-size: x-large; color: grey; padding-top: 3px; margin-inline-start: 20px;">{{$t('restDetails.certificate_not_found')}} </span>
                </div>
                <div v-if="!isLoggedIn">
                  <span
                    style="padding: 10px; margin-inline-start: 20px; font-size: medium; font-weight: bold; color: #062a4e;">
                    {{$t('restDetails.add_cert')}}
                    <router-link to="/login" style="color: rgb(10, 10, 197);">{{$t('restDetails.login')}}</router-link>
                  </span>
                </div>
              </div>

            </div>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <div style="margin-bottom: 30px;">
              <template>
                <v-carousel height="300" style="border: 2px solid #f6f6f6;">
                  <!-- Check if restDetails.pictures is empty -->
                  <v-carousel-item v-if="restDetails.pictures.length === 0" transition="fade-transition">
                    <div class="text-h3 text-center">{{$t('restDetails.pictures_not_found')}}</div>
                  </v-carousel-item>
                  <!-- Display images if restDetails.pictures is not empty -->
                  <v-carousel-item v-for="(item, i) in arrayPictureTrim()" :key="i" :src="host + '/pictures' + item"
                    reverse-transition="fade-transition" transition="fade-transition"
                    @click="showImageModal(`pictures`, item)"></v-carousel-item>
                </v-carousel>
              </template>
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters v-if="isLoggedIn">
          <v-col cols="12">
            <div style="margin-bottom: 30px; border: 1px solid #f6f6f6;">
              <div>
                <span style="padding: 10px; font-size: x-large; font-weight: bold; color: #062a4e;">{{$t('restDetails.rating')}}</span>
                <v-rating background-color="yellow accent-4" color="yellow accent-4" empty-icon="mdi-star-outline"
                  @input="addUpdateRate()" full-icon="mdi-star" hover length="5" size="30" v-model="rate"></v-rating>
              </div>
              <div style="margin-top: 20px;">
                <span style="padding: 10px;">{{ actionTitle }}</span>
              </div>
              <div style="padding: 10px;">
                <v-textarea outlined dense name="input-7-4" label="..." rows="3" v-model="review"></v-textarea>
              </div>
              <div class="text-center" style="padding: 10px;">
                <v-btn @click="addUpdateReview()">{{$t('restDetails.save_review')}}</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="10">
            <div style="margin-bottom: 30px; border: 1px solid #f6f6f6;">
              <div style="margin-top: 20px; border-bottom: 1px solid #e6e3e3;">
                <span style="padding: 10px; font-size: x-large; font-weight: bold; color: #062a4e;">{{
                  restDetails.rates.totalReviews }}
                  {{$t('restDetails.reviews')}}</span>
                <span v-if="!isLoggedIn"
                  style="padding: 10px; margin-inline-start: 20px; font-size: medium; font-weight: bold; color: #062a4e;">
                  {{$t('restDetails.add_rate')}}
                  <router-link to="/login" style="color: rgb(10, 10, 197);">{{$t('restDetails.login')}}</router-link>
                </span>
              </div>
              <v-row v-for="(review, i) in restDetails.rates.reviews" :key="i">
                <v-col cols="1" class="d-flex align-center " style="padding-inline-end: 0px !important">
                  <span style="padding: 10px; font-size: small; font-weight: bold; color: #062a4e;">{{ i + 1 }}.</span>
                </v-col>
                <v-col cols="11" style="padding-inline-start: 0px !important">
                  <div>
                    <span style="font-size: small; color: grey; margin-top: 5px;">{{ review.userName }}</span>
                    <span style="color: rgb(201, 200, 200);"> | </span>
                    <span style="font-size: small; color: grey; margin-top: 5px;">{{ review.date }}</span>
                  </div>

                  <span style="font-size: medium; font-weight: 600; color: #062a4e;">{{ review.review }}</span>

                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <div class="hidden-sm-and-up"
      style="position: fixed; bottom: 50px; right: 0px;  background-color: #f1a9a073; border-radius: 10px;">
      <v-btn icon @click="editRest()">
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
    <div class="hidden-sm-and-down"
      style="position: fixed; bottom: 50px; right: 40px; margin: 3px; padding: 7px; font-weight: 600; background-color: #f1a9a073; border-radius: 10px;">
      <span class="mr-2" style="font-size: medium;">
        {{$t('restDetails.missing_details')}}
        <span @click="editRest()" style="color: rgb(9, 9, 243); cursor: pointer; font-weight: 600;"> {{$t('restDetails.edit_now')}}</span>
      </span>
    </div>

    <v-row v-if="progressShow">
      <v-col class="d-flex justify-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>

    <imageModal v-if="isImageModalVisible" :image-url="modalImageUrl" @close="closeImageModal"></imageModal>
    <ShareDialog v-model="shareDialog" v-if="shareDialog" :itemObj="restDetails"></ShareDialog>
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import ShareDialog from '@/components/rest-details-page/dialogs/ShareDialog'
import CheckOpen from "@/util/CheckOpen";
import Auth from '@/services/auth.service.js'
import { Fancybox } from "@fancyapps/ui";
import AdSense from '@/components/widgets/AdSense.vue';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Constants from '@/util/GetConstant'

export default {
  components: {
    SnackBar,
    ShareDialog,
    AdSense
  },
  data() {
    return {
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      host: "",
      restDetails: {},
      shareDialog: false,
      isOpen: true,
      isImageModalVisible: false,
      modalImageUrl: "",
      review: "",
      rate: "0",
      review: "",
      isLoggedIn: false,
      isAdmin: false,
      constants: {},
    }
  },
  methods: {
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    moveToEdit() {
      console.log(11111111);
      this.$router.push({ name: "adminAccount", params: { _id: this.restDetails._id, tab: "עריכת מסעדה" } })
    },
    async getSingleRestDetails() {
      try {
        this.progressShow = true;
        const idJSON = JSON.stringify({ id: this.$route.params.id, platform: "website" });
        const api = process.env.VUE_APP_BASE_URL + `/rest/get_single_rest`;
        const res = await fetch(api, ApiServices.requestOptions("POST", idJSON, ""));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 401) {
          localStorage.removeItem("token")
          this.isLoggedIn = false;
        } else if (res.status === 200) {
          this.restDetails = jsonObject;
          this.$emit('setRestName', this.restDetails.name)
          this.isOpenNow();
          this.getUserRate();
          this.getUserReview();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurant: " + error, "red");
      }
    },
    async addUpdateRate() {
      try {
        const token = localStorage.getItem("token");
        const idJSON = JSON.stringify({ rate: this.rate, restId: this.restDetails._id });
        const api = process.env.VUE_APP_BASE_URL + `/user/add_update_rate`;
        const res = await fetch(api, ApiServices.requestOptions("POST", idJSON, token));
        const jsonObject = await res.json();
        if (res.status === 400) {
          this.showSnackBar("שגיאה בהוספת דירוג" + jsonObject.message, "red");
        } else if (res.status === 401) {
          localStorage.removeItem("token")
          this.isLoggedIn = false;
        } else if (res.status === 200) {
          this.showSnackBar("הדירוג נוסף בהצלחה", "green");
        }
      } catch (error) {
        this.showSnackBar("Error add rate: " + error, "red");
      }
    },
    async addUpdateReview() {
      try {
        this.progressShow = true;
        const token = localStorage.getItem("token");
        const idJSON = JSON.stringify({ review: this.review, restId: this.restDetails._id });
        const api = process.env.VUE_APP_BASE_URL + `/user/add_update_review`;
        const res = await fetch(api, ApiServices.requestOptions("POST", idJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בהוספת ביקורת" + jsonObject.message, "red");
        } else if (res.status === 201) {
          this.showSnackBar("שגיאה בהוספת ביקורת: מכיל תוכן פוגעני", "red");
        } else if (res.status === 401) {
          localStorage.removeItem("token")
          this.isLoggedIn = false;
        } else if (res.status === 200) {
          this.showSnackBar("הביקורת נוספה בהצלחה", "green");
        }
      } catch (error) {
        this.showSnackBar("Error add review: " + error, "red");
      }
    },
    navigate() {
      if (window.innerWidth < 600) {
        // Open Google Maps app with a deep link
        const googleMapsDeepLink = `geo:${this.restDetails.location.latitude},${this.restDetails.location.longitude}`;
        window.location.href = googleMapsDeepLink;
      } else {
        const modeOfTransportation = "driving"; // You can change this to "walking", "transit", etc. as needed
        const googleMapsWebLink = `https://www.google.com/maps/dir/?api=1&destination=${this.restDetails.location.latitude},${this.restDetails.location.longitude}&travelmode=${modeOfTransportation}`;
        // Open Google Maps website on other devices
        // const googleMapsWebLink = `https://www.google.com/maps?q=${this.restDetails.location.latitude},${this.restDetails.location.longitude}`;
        window.open(googleMapsWebLink, '_blank');
      }
    },
    call() {
      window.location.href = `tel:${this.restDetails.phone}`;
    },
    isOpenNow() {
      const OpenStatus = CheckOpen.checkOpen(this.restDetails.open_hours);
      this.isOpen = OpenStatus
    },
    getUserRate() {
      const userId = Auth.getUserId();
      const ratesList = this.restDetails.rates.ratings;
      const hasUserRated = ratesList.find(rate => rate.userId === userId);
      if (hasUserRated) {
        this.rate = hasUserRated.rate;
      }
    },
    getUserReview() {
      const userId = Auth.getUserId();
      const reviewsList = this.restDetails.rates.reviews;
      const hasUserReview = reviewsList.find(review => review.userId === userId);
      if (hasUserReview) {
        this.review = hasUserReview.review;
      }
    },
    showImageModal(type, url) {
      this.isImageModalVisible = true;
      this.modalImageUrl = this.host + `/${type}` + url;
    },
    closeImageModal() {
      this.isImageModalVisible = false;
      this.modalImageUrl = "";
    },
    editRest() {
      let auth = Auth.checkAuth();
      if (auth) {
        this.$router.push({ name: "userAccount", params: { _id: this.$route.params.id } })
      } else {
        this.$router.push({ name: "login" })
      }
    },
    arrayPictureTrim() {
      return this.restDetails.pictures.filter(pic => pic)
    },
    loadAdSenseScript() {
      return new Promise((resolve, reject) => {
        if (document.querySelector('script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async getConstants() {
      const constants = await Constants.getConstant();
      this.constants = constants
    },
    openFancybox(src) {
      const options = {
        Toolbar: {
          display: {
            left: ["infobar"],
            middle: [
              "zoomIn",
              "zoomOut",
              "rotateCCW",
              "rotateCW",
            ],
          },
        },
      };
      Fancybox.show([
        {
          src: src,
          type: 'image',

          // caption: 'Your caption here'
        },
      ], options);
    }
  },
  computed: {
    actionTitle() {
      if (this.review) {
        return this.$t('restDetails.edit_review');
      } else {
        return this.$t('restDetails.save_review');
      }
    },
    getPicture() {
      return this.restDetails.logo ? this.host + '/logo' + this.restDetails.logo : require('@/assets/default_logo.png');
    }
  },
  watch: {
  },
  mounted() {
    this.loadAdSenseScript().then(() => {
      (adsbygoogle = window.adsbygoogle || []).push({});
    }).catch((error) => {
      console.error('Failed to load AdSense script:', error);
    });

    this.getSingleRestDetails()
    this.host = process.env.VUE_APP_BASE_URL;
    if (Auth.checkAuth()) {
      this.isLoggedIn = true;
    }
    if (Auth.getUserRole() === "admin") {
      this.isAdmin = true;
    }

    this.constants = this.$store.state.constant;
    if (!this.constants?.types) {
      this.getConstants();
    }
  },

}
</script>
<style scoped>
.actionStyle {
  width: 100%;
  height: 60px;
}

.actionStyle2 {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.non-pointer {
  pointer-events: none;
}
</style>
      
  
  